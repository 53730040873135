import React, { useState } from 'react';
import { useModal } from './ModalContext';

export const LearnMoreModal: React.FC = () => {
    const { isFormOpen, closeFormModal } = useModal() || {};

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: ''
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        console.log('Submitting form data:', formData); 
        fetch('https://script.google.com/macros/s/AKfycbwMydNNQU62EQFt7WvNhXB1unSmv0Jl0HfQoUMdP5eGaEzBF70PDhoBQxC2o411X9gIAA/exec', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(`HTTP error! status: ${response.status}`);
                }
                alert("Form submitted successfully!");
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    message: ''
                });
                closeFormModal?.();
            })
            .catch((error) => {
                console.error("Error submitting form:", error);
                alert(`An error occurred while submitting the form. Please try again. Error: ${error.message || error}`);
            })
            .finally(() => {
                setIsLoading(false);
            });
        
    };

    return (
        <div>
            {isFormOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 w-full">
                    <div onClick={closeFormModal} className="modal-overlay absolute w-full h-full bg-[#000000] opacity-75"></div>
                    <div className="modal-container w-full md:max-w-2xl mx-auto border-radius overflow-y-auto" style={{ zIndex: 9999 }}>
                        <div className="modal-content py-4 text-left px-6">
                            <div className="p-8 max-w-lg w-full mx-auto rounded-xl bg-white rounded-lg shadow-lg">
                                <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Redeem your prize</h2>
                                <form className="space-y-6" onSubmit={handleSubmit}>
                                    <div>
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full px-4 py-3 border border-gray-300 rounded-xl"
                                            placeholder="Enter your name"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full px-4 py-3 border border-gray-300 rounded-xl"
                                            placeholder="Enter your email"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">Phone</label>
                                        <input
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full px-4 py-3 border border-gray-300 rounded-xl"
                                            placeholder="Enter your phone number"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">Company</label>
                                        <input
                                            type="text"
                                            name="company"
                                            value={formData.company}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full px-4 py-3 border border-gray-300 rounded-xl"
                                            placeholder="Company name"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">Message</label>
                                        <textarea
                                            name="message"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            required
                                            rows={4}
                                            className="w-full px-4 py-3 border border-gray-300 rounded-xl"
                                            placeholder="Your message here..."
                                        />
                                    </div>

                                    <div className="flex justify-between items-center mt-6 space-x-4">
                                        <button
                                            type="button"
                                            onClick={closeFormModal}
                                            className="w-1/2 py-3 text-gray-700 bg-gray-200 rounded-xl"
                                            disabled={isLoading}
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="submit"
                                            className="w-1/2 py-3 text-white bg-blue-500 rounded-xl"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Submitting...' : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
