

import './index.css';
import { ModalProvider } from './components/ModalContext';
import IndexPage from './components';

function App() {
  

  return (
    <div>
      <ModalProvider>
        <IndexPage />
      </ModalProvider>

    </div>
  );
}

export default App;
