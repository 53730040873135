"use client";

import { FiPhone, FiMail, FiGlobe } from "react-icons/fi";

export default function ContactUs() {
  return (
    <section className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 via-purple-700 to-black text-white p-6 sm:p-16">
      {/* Background Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent to-gray-800 opacity-30 pointer-events-none"></div>

      {/* Decorative Logo Icon */}
      <div className="text-8xl sm:text-9xl mb-8 text-gray-300 animate-pulse">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-20 h-20"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 5v14m7-7H5" />
        </svg>
      </div>

      <h1 className="text-4xl sm:text-5xl font-bold text-center tracking-wide mb-6 sm:mb-8">
        Transform Your Business Today
      </h1>

      <p className="text-center text-lg sm:text-xl text-gray-400 leading-relaxed max-w-3xl mb-10">
        Discover how we help businesses like yours achieve digital success.{" "}
        <strong className="text-white">Get in touch</strong> for a complimentary consultation and take the first step towards growth.
      </p>

      {/* Divider */}
      <hr className="w-full border-gray-700 my-8" />

      {/* Contact Information */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-12 text-center text-lg text-gray-300">
        {/* Phone */}
        <ContactCard
          icon={<FiPhone className="text-4xl text-teal-400 hover:text-teal-300 transition duration-300" />}
          label="Phone"
          content="+447590908648"
        />
        <ContactCard
          icon={<FiMail className="text-4xl text-teal-400 hover:text-teal-300 transition duration-300" />}
          label="Email"
          content={
            <a
              href="mailto:sales@avswest.com"
              className="hover:underline hover:text-teal-300 transition"
            >
              Adewale@aventurestud.io
            </a>
          }
        />
        <ContactCard
          icon={<FiGlobe className="text-4xl text-teal-400 hover:text-teal-300 transition duration-300" />}
          label="Website"
          content={
            <a
              href="https://aventurestud.io"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline hover:text-teal-300 transition"
            >
              aventurestud.io
            </a>
          }
        />
      </div>

      {/* Footer Note */}
      {/* <p className="text-sm text-gray-500 mt-12 sm:mt-16">
        Free consultation available for a limited time only.
      </p> */}

      {/* Call to Action Button */}
      {/* <a
        href="#contact-form"
        className="mt-8 sm:mt-12 px-6 py-3 bg-teal-400 text-white text-xl font-semibold rounded-xl hover:bg-teal-500 transition duration-300"
      >
        Schedule Your Free Consultation
      </a> */}
    </section>
  );
}

function ContactCard({ icon, label, content }: any) {
  return (
    <div className="flex flex-col items-center">
      {icon}
      <h3 className="text-xl font-semibold mt-4">{label}</h3>
      <p className="mt-2">{content}</p>
    </div>
  );
}
