import React from "react";

interface SectionProps {
  title: string;
  description: string;
}

const Section: React.FC<SectionProps> = ({ title, description }) => (
  <div className="bg-gray-800 p-6 rounded-xl shadow-xl transform transition-transform duration-500 ease-out hover:scale-105 hover:shadow-2xl hover:opacity-90 group animate-slideIn">
    <h3 className="text-lg sm:text-xl font-semibold text-white mb-4 group-hover:text-purple-400 transition-all duration-300">
      {title}
    </h3>
    <p className="text-gray-400 text-sm transition-all duration-300 group-hover:text-white">
      {description}
    </p>
  </div>
);

const GrowthPathways: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-purple-700 to-black text-white p-6 sm:p-16">
      <div className="w-full max-w-6xl mx-auto">
        {/* Header Section */}
        <p className="text-start text-2xl sm:text-3xl md:text-5xl font-extrabold text-white tracking-widest mb-16">
          Why Choose AVS?
        </p>

        {/* Grid Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <Section
            title="Tailored Solutions"
            description="We understand your unique needs and deliver personalized digital experiences."
          />
          <Section
            title="Expert Team"
            description="Our skilled professionals are dedicated to turning your ideas into functional, scalable solutions."
          />
          <Section
            title="Innovative Approach"
            description="Stay ahead of the competition with our forward-thinking technology and design strategies."
          />
        </div>

        {/* Footer Section */}
        <hr className="border-gray-600 my-8" />
        <footer className="mt-8 text-gray-500 text-sm text-center sm:text-left">
          <a
            href="https://aventurestudio.io"
            className="hover:text-gray-300 transition-all duration-300"
          >
            https://aventurestudio.io
          </a>
        </footer>
      </div>
    </div>
  );
};

export default GrowthPathways;
