import React, { useEffect } from 'react'
import { FloatingNav } from './ui/FloatingNav'
import Hero from './Hero'
import Grid from './Grid'
import GrowthPathways from './GrowthPathWays'
import TabsGrid from './TabsGrid'
import ContactUs from './ContactUs'
import { FaHome } from 'react-icons/fa'
import { useModal } from './ModalContext'
import SpinModal from './SpinModal'
import GrowthServices from './GrowthService'
import { LearnMoreModal } from './FormModal'

export default function IndexPage() {
    const { openSpinModal } = useModal();

    useEffect(() => {
        openSpinModal();
    }, []);

    return (
        <div className="max-2-7xl w-full">
            {/* <SpinModal /> */}
            <LearnMoreModal />
            <FloatingNav navItems={[{ name: 'Home', link: '/', icon: <FaHome /> }
            ]} />
            <Hero />
            {/* <Grid /> */}
            <GrowthPathways />
            <GrowthServices/>
            {/* <TabsGrid /> */}
            <ContactUs />
            {/* <SpinningWheel /> */}
        </div>
    )
}
