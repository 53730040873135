import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ModalContextType {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  isFormOpen: boolean;
  openFormModal: () => void;
  closeFormModal: () => void;
  isSpinOpen: boolean;
  openSpinModal: () => void;
  closeSpinModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFormOpen, setIsFormModalOpen] = useState<boolean>(false)
  const [isSpinOpen, setIsSpinOpen] = useState<boolean>(false)

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openFormModal = () => setIsFormModalOpen(true);
  const closeFormModal = () => setIsFormModalOpen(false);
  const openSpinModal = () => setIsSpinOpen(true);
  const closeSpinModal = () => setIsSpinOpen(false);

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, isFormOpen, openFormModal, closeFormModal, isSpinOpen, openSpinModal, closeSpinModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
