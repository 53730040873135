import React, { useState } from 'react';
import { Spotlight } from './ui/Spotlight';
import { FaLocationArrow } from 'react-icons/fa';

const Hero = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    return (
        <div className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-gray-900 via-purple-800 to-black text-white">
            {/* Enhanced Spotlight Effects */}
            <div className="absolute inset-0 pointer-events-none">
                <Spotlight
                    className="absolute animate-pulse blur-xl opacity-60 w-[150vw] h-[150vh] top-0 left-0"
                    fill="rgba(255, 255, 255, 0.07)"
                />
                <Spotlight
                    className="absolute blur-lg opacity-50 w-[100vw] h-[100vh] top-1/4 right-1/4"
                    fill="rgba(144, 0, 255, 0.12)"
                />
                <Spotlight
                    className="absolute blur-lg opacity-40 w-[120vw] h-[120vh] bottom-0 left-1/3"
                    fill="rgba(0, 128, 255, 0.1)"
                />
            </div>

            {/* Content Container */}
            <div className="relative z-10 text-center max-w-5xl px-8">
                {/* Headline */}
                <h1 className="text-6xl sm:text-7xl lg:text-8xl font-bold leading-tight tracking-tight mb-8 text-shadow-lg animate-fadeInUp">
                    Transform Your Vision into Reality with AVS!
                </h1>

                {/* Subtitle */}
                <p className="text-lg sm:text-2xl lg:text-3xl max-w-3xl mx-auto leading-relaxed mb-12 opacity-90 animate-fadeIn delay-200">
                    At AVS, we craft cutting-edge software, design stunning websites,
                    and build apps that redefine innovation. Take the next step with us today.
                </p>

                {/* Call to Action */}
                <button
                    onClick={toggleModal}
                    className="group inline-flex items-center gap-4 px-12 py-5 rounded-full text-xl font-semibold bg-gradient-to-r from-green-500 to-blue-700 text-black shadow-lg backdrop-blur-lg transition-transform duration-300 transform hover:scale-110 hover:shadow-2xl"
                >
                    Learn More
                    <FaLocationArrow className="ml-2 transition-transform duration-300 group-hover:translate-x-2" />
                </button>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
                    <div className="relative bg-white text-black rounded-lg shadow-lg max-w-md w-full">
                        {/* Modal Header */}
                        <div className="flex justify-between items-center p-4 border-b">
                            <h3 className="text-lg font-semibold">Contact Us</h3>
                            <button
                                onClick={toggleModal}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                ✕
                            </button>
                        </div>

                        {/* Modal Body */}
                        <div className="p-6">
                            <form className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                        placeholder="Enter your name"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                        placeholder="Enter your email"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Message
                                    </label>
                                    <textarea
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                        // rows="4"
                                        placeholder="Write your message"
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="w-full bg-gradient-to-r from-green-500 to-blue-700 text-white py-2 px-4 rounded shadow hover:opacity-90 focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Hero;
