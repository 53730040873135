import React from 'react';
import { FaMobileAlt, FaCode, FaGlobe, FaRegLightbulb } from 'react-icons/fa';

const ServiceItem = ({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) => (
    <div className="bg-gradient-to-r from-black to-gray-900 p-8 shadow-lg hover:shadow-2xl transition-transform duration-300 hover:scale-105 rounded-2xl transform group">
        <div className="flex items-center mb-4">
            <div className="text-5xl text-white group-hover:text-teal-200 transition-all">{icon}</div>
        </div>
        <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
        <p className="text-gray-200 text-sm leading-relaxed">{description}</p>
    </div>
);

const OurServices = () => {
    return (
        <section className="bg-gradient-to-b from-gray-800 via-black to-gray-900 text-white py-24 pt-10 px-8">
            {/* Section Header */}
            <div className="text-center mb-16">
                <p className="text-lg font-medium text-black-400 uppercase tracking-widest mb-2">
                    Our Expertise
                </p>
                <h2 className="text-4xl md:text-5xl font-extrabold mb-4">
                    Turning Your Ideas into Innovative Solutions
                </h2>
                <p className="text-gray-300 text-base md:text-lg max-w-4xl mx-auto">
                    Our team specializes in delivering custom-built solutions for businesses of all sizes, driving digital success and transformation.
                </p>
            </div>

            {/* Services Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 max-w-7xl mx-auto">
                <ServiceItem
                    icon={<FaMobileAlt className="text-teal-200" />}
                    title="Mobile App Development"
                    description="Crafting seamless and scalable mobile applications to boost your business operations."
                />
                <ServiceItem
                    icon={<FaCode className="text-yellow-300" />}
                    title="Custom Software Solutions"
                    description="Building efficient and powerful software that aligns with your business goals."
                />
                <ServiceItem
                    icon={<FaGlobe className="text-blue-400" />}
                    title="Website Design & Development"
                    description="Creating stunning websites designed for both aesthetics and performance."
                />
                <ServiceItem
                    icon={<FaRegLightbulb className="text-purple-400" />}
                    title="Digital Strategy & Consulting"
                    description="Helping businesses innovate and grow with tailored digital strategies."
                />
            </div>
        </section>
    );
};

export default OurServices;
